export function EmailSubscribe() {
  return (
    <form method="post" action="https://list.brehoni.cz/subscription/form">
      <input type="hidden" name="nonce" />
      <input
        type="hidden"
        name="l"
        value="c9da1270-16b2-4085-8741-373da3b1656b"
      />
      <div className="flex flex-col gap-2">
        <label>
          <div className="block text-sm font-semibold leading-6 text-gray-900">
            Email
          </div>
          <input
            className="h-10 grow appearance-none rounded-md bg-white px-3 text-slate-900 shadow-sm ring-1 ring-slate-200 placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:text-sm"
            type="email"
            name="email"
            required
            placeholder="surtr@brehoni.cz"
          />
        </label>
        <label>
          <div className="block text-sm font-semibold leading-6 text-gray-900">
            Jméno (volitelné)
          </div>
          <input
            className="h-10 grow appearance-none rounded-md bg-white px-3 text-slate-900 shadow-sm ring-1 ring-slate-200 placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:text-sm"
            type="text"
            name="name"
            placeholder="Surtr"
          />
        </label>

        <p className="prose">
          Posíláme převážně pozvánky na akce - obvykle nejvýše jednu zprávu do
          měsíce.
        </p>

        <input
          type="submit"
          value="Přihlásit se"
          className="inline-flex w-56 justify-center rounded-lg
              bg-slate-900 py-2.5
              px-4 text-sm
              font-semibold text-white
              no-underline
              hover:bg-slate-700
              not-prose
            "
        />
      </div>
    </form>
  )
}
